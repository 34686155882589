<template>
  <b-form>
    <b-row>
      <b-col>
        <h1>Page detail</h1>
        <b-form-checkbox
          v-model="page.active"
          name="active"
          class="content-switch"
          switch
          ><b>Active</b></b-form-checkbox
        >
      </b-col>
      <b-col cols="3" align-self="center">
        <b-alert
          align-v="center"
          variant="warning"
          :show="saveMessage !== ''"
          >{{ this.saveMessage }}</b-alert
        >
      </b-col>
      <b-col cols="auto">
        <b-button
          class="mb-5 save-button"
          @click="save()"
          :disabled="saveDisabled"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-form-group label="Link slug">
      <b-input-group :prepend="`${baseUrl}pages/`">
        <b-form-input v-model="page.link" />
      </b-input-group>
    </b-form-group>
    <a :href="`${baseUrl}pages/${page.link}`">{{
      `${baseUrl}pages/${page.link}`
    }}</a>
    <b-tabs class="mt-3">
      <b-tab v-for="language in languages" :key="language">
        <template #title>
          <country-flag :country="language" size="normal" />
        </template>
        <b-form-checkbox v-model="editHtml">
          Use HTML
        </b-form-checkbox>
        <b-form-group label="Content" v-if="!editHtml">
          <quill-editor
            :options="editorOption"
            @change="onEditorChange($event)"
            v-model="page.html[language]"
            :theme="snow"
          ></quill-editor>
        </b-form-group>
        <b-form-group label="HTML" v-else>
          <b-textarea v-model="page.html[language]"></b-textarea>
        </b-form-group>
        <h2>Preview</h2>
        <b-container class="mb-5 background-image">
          <static-page-display :page="page" />
        </b-container>
      </b-tab>
    </b-tabs>
  </b-form>
</template>
<script>
import StaticPageDisplay from "@/components/admin/static-pages/StaticPageDisplay.vue";
import { StaticPage } from "@/models/static-page.model";

export default {
  components: {
    StaticPageDisplay,
  },
  data() {
    return {
      editHtml: false,
      saveDisabled: true,
      saveMessage: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video"],
          ],
        },
      },
    };
  },
  async created() {
    if (this.$route.params.id) {
      await this.$store.dispatch("Pages/fetchById", this.$route.params.id);
    } else {
      this.$store.commit("Pages/SET_CURRENT", new StaticPage(true, "", {}));
    }
    this.saveDisabled = true;
    this.saveMessage = "";
  },
  watch: {
    page: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  methods: {
    async save() {
      this.isLoading = true;

      if (this.$route.params.id) {
        await this.$store.dispatch("Pages/update", this.page);
      } else {
        await this.$store.dispatch("Pages/create", this.page);
        this.$router.push({
          name: "page-detail",
          params: { id: this.page.id },
        });
      }

      this.$root.$bvToast.toast("Page settings saved!", { variant: "success" });

      this.saveDisabled = true;
      this.saveMessage = "";
      this.isLoading = false;
    },
  },
  computed: {
    page() {
      return this.$store.state.Pages.current;
    },
    languages() {
      return this.$store.state.Languages.all;
    },
    baseUrl() {
      let url = `${window.location.origin}${process.env.VUE_APP_BASE_URL}`;
      if (url.endsWith("/")) {
        return url;
      }
      return `${url}/`;
    },
  },
};
</script>
<style lang="scss">
$background-image: var(--theme-background-image);

.background-image {
  background-image: $background-image;
  // position: absolute;
  width: 100%;
  background-size: cover;
  // overflow: auto;
}

.ql-align-center {
  text-align: center;
}

.ql-snow .ql-editor {
  background-color: #a9a9a9;
  overflow: visible;
}
</style>
